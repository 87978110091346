import styles from './invitation.module.css';
import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const balloonPath = `${process.env.PUBLIC_URL}/images/party-balloon-icon-orange.svg`;
const inviteWidth = 1080;
const inviteHeight = 1080;

function drawFlourish(x, y, width, height) {
    const context = d3.path();
    // curve
    context.moveTo(x, y);
    context.quadraticCurveTo(x + width / 4, y + height / 2, x + width / 2, y);
    context.quadraticCurveTo(x + (3 * width) / 4, y - height / 2, x + width, y);

    // center tick
    context.moveTo(x + width / 2 - 10, y - 10);
    context.lineTo(x + width / 2 + 10, y + 10);

    // left balloon
    // context.moveTo(x, y);
    // context.quadraticCurveTo(x, y - 50, x - 10, y - 50);
    // context.quadraticCurveTo(x - 30, y - 75, x - 50, y - 45);
    return context.toString();
}

export function Invitation() {
    const container = useRef();
    // const [width, setWidth] = useState(1152);

    useEffect(() => {
        const svg = d3
            .select(container.current)
            .append('svg')
            .attr('xmlns', 'http://www.w3.org/2000/svg')
            .attr('width', inviteWidth)
            .attr('height', inviteHeight);
        const text = svg
            .append('text')
            .attr('x', inviteWidth / 2)
            .attr('y', 150)
            .attr('text-anchor', 'middle')
            .attr('class', styles.heading)
            .text("Brigette's 30th Birthday!");

        const textBox = text.node().getBBox();
        svg.append('path')
            .attr(
                'd',
                drawFlourish(
                    textBox.x,
                    textBox.y + textBox.height,
                    textBox.width,
                    50,
                ),
            )
            .attr('stroke-width', 3)
            .attr('stroke', '#ffb091')
            .attr('fill', 'transparent');
        // left balloon
        svg.append('g')
            .attr(
                'transform',
                `translate(${textBox.x - 107} ${textBox.y + 15}) rotate(-30)`,
            )
            .append('image')
            .attr('href', balloonPath)
            .attr('height', 150);
        // right balloon
        svg.append('g')
            .attr(
                'transform',
                `translate(${textBox.x + textBox.width + 39} ${textBox.y - 23}) rotate(30)`,
            )
            .append('image')
            .attr('href', balloonPath)
            .attr('height', 150);

        const rectWidth = 1000;
        const rectHeight = 800;
        const infoBox = svg
            .append('rect')
            .attr('width', rectWidth)
            .attr('height', rectHeight)
            .attr('x', (inviteWidth - rectWidth) / 2)
            .attr('y', textBox.height + textBox.y + 50)
            .attr('rx', 50)
            .attr('fill', 'transparent')
            .attr('stroke-width', 3)
            .attr('stroke', '#cf91ff');

        svg.append('foreignObject')
            .attr('x', (inviteWidth - rectWidth) / 2)
            .attr('y', textBox.height + textBox.y + 50)
            .attr('width', rectWidth)
            .attr('height', rectHeight)
            .append('xhtml:p')
            .attr('class', styles.info)
            .text(
                "We would be honored if you would join us in celebrating Brigette's 30th birthday",
            );
    }, [container]);

    // useEffect(() => {
    //     if (container.current) {
    //         setWidth(container.current.offsetWidth);
    //     }
    // }, [container]);
    return <div className={styles.cardContainer} ref={container}></div>;
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import Styles from './rsvp.module.css';

const sortGuests = (guests) =>
    guests.sort((a, b) => a.name.localeCompare(b.name));

const getGuestClass = (guest) => {
    switch (guest.status) {
        case 'Coming':
            return Styles.attending;
        case 'Not coming':
            return Styles.notAttending;
        default:
            return Styles.noResponse;
    }
};

const guestFilter = (filter) => (guest) => {
    return (
        guest.name
            .trim()
            .toLowerCase()
            .startsWith(filter.trim().toLowerCase()) ||
        guest.name
            .split(' ')
            .map((name) => name.trim().toLowerCase())
            .filter(Boolean)
            .some((name) => name.startsWith(filter))
    );
};

export function Rsvp() {
    const [displayRsvp, setDisplayRsvp] = useState(false);
    const [guests, setGuests] = useState();
    const [selectedGuests, setSelectedGuests] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        fetch('https://brigette.goodmanson.me/api/GuestList')
            .then((res) => res.json())
            .then((res) => setGuests(sortGuests(res)));
    }, []);

    const selectGuest = (guest) => {
        setSelectedGuests([...selectedGuests, guest]);
        setGuests(sortGuests(guests.filter((g) => g.id !== guest.id)));
    };

    const saveResponse = (guest) => (event) => {
        const select = event.target.previousElementSibling;
        const isComing = select.value === 'true';
        fetch(`https://brigette.goodmanson.me/api/GuestList/${guest.id}`, {
            method: 'post',
            body: JSON.stringify({
                status: isComing ? 'Coming' : 'Not coming',
            }),
        }).then(() => {
            guest.status = isComing ? 'Coming' : 'Not coming';
            setSelectedGuests(selectedGuests.filter((g) => g.id !== guest.id));
            setGuests(sortGuests([...guests, guest]));
        });
    };

    const handleFilterChange = () => (event) => setFilter(event.target.value);

    return (
        <div className={Styles.rsvpContainer}>
            {!displayRsvp && (
                <div className={Styles.center}>
                    <button
                        onClick={() => setDisplayRsvp(!displayRsvp)}
                        className={Styles.rsvpButton}
                    >
                        RSVP
                    </button>
                </div>
            )}
            {displayRsvp && (
                <div className={Styles.columnsContainer}>
                    <div className={Styles.usersContainer}>
                        <h2 className={Styles.center}>Guests</h2>
                        <div className={Styles.legend}>
                            <h3>Legend:&nbsp;</h3>
                            <div className={Styles.legendLabels}>
                                <span className={Styles.attending}>
                                    Attending
                                </span>
                                <span className={Styles.notAttending}>
                                    Not attending
                                </span>
                                <span className={Styles.noResponse}>
                                    No response
                                </span>
                            </div>
                        </div>
                        <div>
                            <label>Filter:</label>
                            <input onKeyUp={handleFilterChange()} />
                        </div>
                        {!guests ? (
                            <p>Loading...</p>
                        ) : (
                            <ul className={Styles.guestList}>
                                {guests
                                    .filter(guestFilter(filter))
                                    .map((guest) => (
                                        <li key={guest.id}>
                                            <button
                                                className={getGuestClass(guest)}
                                                onClick={() =>
                                                    selectGuest(guest)
                                                }
                                            >
                                                {guest.name}
                                            </button>
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                    <div className={Styles.editContainer}>
                        <h2 className={Styles.center}>Edit</h2>
                        {!selectedGuests.length ? (
                            <p>Select a guest to edit their rsvp status</p>
                        ) : (
                            <ul className={Styles.guestList}>
                                {selectedGuests.map((guest) => (
                                    <li
                                        key={guest.id}
                                        className={getGuestClass(guest)}
                                    >
                                        <span>{guest.name}:&nbsp;</span>
                                        <select
                                            defaultValue={
                                                guest.status === 'Coming' ||
                                                guest.status === 'pending'
                                                    ? 'true'
                                                    : 'false'
                                            }
                                        >
                                            <option value={'true'}>
                                                I will be attending
                                            </option>
                                            <option value={'false'}>
                                                I will not be able to attend
                                            </option>
                                        </select>
                                        <button onClick={saveResponse(guest)}>
                                            Save response
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

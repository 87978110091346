import { Invitation } from './component/invitation/invitation';
import styles from './app.module.css';
import { Rsvp } from './component/rsvp/rsvp';

function App() {
    const mapWidth = Math.min(window.screen.width, 600);
    return (
        <div className={styles.contentContainer}>
            <h1 className={styles.primaryCursive}>Brigette's 30th Birthday!</h1>
            <p className={styles.complementary}>
                Join us for a fun night of drinks, snacks, and dancing!
            </p>
            <p
                style={{
                    padding: '0 5px',
                    textAlign: 'center',
                }}
            >
                The party will be at the DoubleTree by Hilton hotel in St. Paul
                from 6PM to Midnight:
            </p>
            <a href="https://maps.app.goo.gl/zPLSr7LbxXcQNUY89">
                411 Minnesota St, St Paul, MN 55101
            </a>
            <a
                href="https://www.hilton.com/en/attend-my-event/30th-birthday-party-2024/"
                target="_blank"
                style={{
                    margin: '10px 0',
                    textAlign: 'center',
                }}
            >
                Reserve a room in our room block for $109.00 a night
            </a>
            <div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5647.585041212879!2d-93.0933644!3d44.9478856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f7d54dc27f1e37%3A0x32003d269bf6a32a!2s411%20Minnesota%20St%2C%20St%20Paul%2C%20MN%2055101!5e0!3m2!1sen!2sus!4v1711071413764!5m2!1sen!2sus"
                    width={mapWidth}
                    height="450"
                    style={{
                        border: 0,
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <Rsvp />
        </div>
    );
}

export default App;
